import BNB from "../images/bnb.png";
import USDT from "../images/usdt.svg";
export const list = [
  {
    icon: BNB,
    name: "BNB",
  },
  {
    icon: USDT,
    name: "USDT",
  },
];
