import React, { useEffect, useState } from "react";
import "./style.css";
import logo from "../../images/VICTA.png";
import Abi from "../../Helpers/abi.json";
import TokenAbi from "../../Helpers/token.json";
import TokenModal from "./TokenModal";
import { list } from "../../Helpers/tokenlist";
import { FiChevronDown } from "react-icons/fi";
import toast from "react-hot-toast";
import { Web3Button } from "@web3modal/react";
import { useAccount } from 'wagmi';
import Web3 from "web3";
import { prepareWriteContract, writeContract } from "@wagmi/core";

const isValid = (regex) => (input) => regex.test(input);
const numberRegex = /^\d*\.?\d*$/;
const isValidNumber = isValid(numberRegex);

const MainSection = () => {
  const { isConnected, address } = useAccount(); // Removed 'account'

  const cAddress = "0x16d189278bd8e79E8CB122F8a818f6aeDa4A7c73";
  const usdtAddress = "0x55d398326f99059fF775485246999027B3197955";
  const DEFAULT_GAS_PRICE_GWEI = "20"; // Default gas price in Gwei

  const [data, setData] = useState({
    bnb: "",
    gart: "",
  });
  const [open, setOpen] = React.useState(false);
  const [currentToken, setCurrentToken] = useState(list[0]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const gartVal = currentToken.name === "BNB" ? 6762 : 29;

  const webSupply_Binance = new Web3("https://1rpc.io/bnb");

  // BUY WITH BNB
  const processBuy = async () => {
    if (!data.bnb || !data.gart) {
      toast.error("Please enter the correct value.");
      return;
    }

    try {
      const contract = new webSupply_Binance.eth.Contract(Abi, cAddress);
      let bnbValue = await webSupply_Binance.utils.toWei(String(data.bnb), "ether");

      const transaction = await prepareWriteContract({
        address: cAddress,
        abi: Abi,
        functionName: "buyVCTA",
        value: bnbValue,
        from: address,
      });

      const toastId = toast.loading("Processing transaction...");
      const receipt = await writeContract(transaction);

      toast.success("Transaction completed successfully", { id: toastId });
      setData({ bnb: "", gart: "" });
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
    }
  };

  const buyWithUsdt = async () => {
    if (!data.bnb || !data.gart) {
      toast.error("Please enter the correct value.");
      return;
    }
  
    try {
      const contract = new webSupply_Binance.eth.Contract(Abi, cAddress);
      const tokenContract = new webSupply_Binance.eth.Contract(TokenAbi, usdtAddress);
      let bnbValue = webSupply_Binance.utils.toWei(data.bnb.toString());
  
      // Convert BigNumber to number
      const bnbValueNumber = Number(bnbValue);
  
      // Step 1: Approve contract to spend USDT
      const approvalTransaction = await prepareWriteContract({
        address: usdtAddress,
        abi: TokenAbi,
        functionName: "approve",
        args: [cAddress, bnbValueNumber],
        from: address,
      });
  
      const toastId = toast.loading("Allow our protocol to use your token");
      await writeContract(approvalTransaction);
  
      toast.loading("Processing Transaction..", { id: toastId });
  
      // Add a time interval (e.g., 5 seconds) before initiating the buy transaction
      setTimeout(async () => {
        // Step 2: Buy with USDT
        const buyTransaction = await prepareWriteContract({
          address: cAddress,
          abi: Abi,
          functionName: "buyWithUSDT",
          args: [bnbValueNumber],
          from: address,
        });
  
        await writeContract(buyTransaction);
  
        toast.success("Transaction completed successfully", { id: toastId });
        setData({ bnb: "", gart: "" });
      }, 5000); // 5000 milliseconds (5 seconds) interval
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
    }
  };
  
  

  const buyToken = async () => {
    if (isConnected) {
      if (currentToken.name === "BNB") {
        processBuy();
      } else if (currentToken.name === "USDT") {
        buyWithUsdt();
      }
    } else {
      toast.error( "Please connect your wallet." );
    }
  };

  return (
    <div className="main-section">
      <p className="headings">VICTA</p>
      <p className="headings">Buy with BNB and USDT</p>
      <div className="main-section-form">
        <p className="mb-6">
          1 {currentToken.name} = {gartVal} VICTA
        </p>
        <p className="mgtp">Pay with</p>
        <div className="form-group">
          <input
            type="text"
            value={data.bnb}
            className="text-black"
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                bnb: val,
                gart: val * gartVal,
              });
            }}
          />
          <div
            onClick={handleOpen}
            className=" cursor-pointer items-center flex"
          >
            <img src={currentToken.icon} alt="snk" />
            <p>{currentToken.name}</p>
            <FiChevronDown className="text-black" />
          </div>
        </div>
        <p className="mgtp">You will get</p>
        <div className="form-group">
          <input
            type="text"
            className="text-black"
            value={data.gart}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                gart: val,
                bnb: val / gartVal,
              });
            }}
          />
          <div>
            <img src={logo} alt="snk" />
            <p>VICTA</p>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>
          <button className="buy" 
          onClick={buyToken}
          style={{
            backgroundColor: isConnected ? "white" : "gray",
            color: isConnected ? "black" : "black",
            cursor: isConnected ? "pointer" : "not-allowed",
          }}>
            Buy
          </button>
        </div>

        <div className="smart">
          <i className="fa fa-lock" aria-hidden="true"></i>
          <p>100% Secure smart contract</p>
        </div>
      </div>

      <TokenModal
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        currentChain={currentToken}
        setCurrentChain={setCurrentToken}
        setData={setData}
      />
    </div>
  );
};

export default MainSection;
